@config "../../../tailwind.config.ts";
@tailwind components;

@layer components {
  .display-l {
    @apply text-[1.5rem] font-bold leading-[1.8125rem] md:text-[1.4375rem] md:leading-[1.75rem];
  }

  .display-m {
    @apply text-[1.25rem] font-bold leading-[1.5rem] md:text-[1.3125rem] md:leading-[1.625rem];
  }

  .display-s {
    @apply text-[1.25rem] font-bold leading-[1.5rem] md:text-[1.125rem] md:leading-[1.4375rem];
  }

  .headline-xl {
    @apply text-[1.4375rem] leading-[1.8125rem] text-inkwell md:text-[1.5rem] md:leading-[1.875rem];
  }

  .headline-l {
    @apply text-[1.3125rem] leading-[1.625rem] text-inkwell md:text-[1.25rem];
  }

  .headline-m {
    @apply text-[1.125rem] leading-[1.375rem] text-inkwell md:text-[1.0625rem] md:leading-[1.3125rem];
  }

  .headline-s {
    @apply leading-[1.25rem] text-inkwell md:text-[.9375rem] md:leading-[1.1875rem];
  }

  .headline-xs {
    @apply lg:text-[1.063rem] leading-[1.313rem] text-inkwell;
  }
  .body-l {
    @apply leading-[1.375rem] text-batcave md:text-[.9375rem] md:leading-[1.25rem];
  }

  .body-m {
    @apply text-[.9375rem] leading-[1.25rem] md:text-[.875rem] md:leading-[1.1875rem];
  }

  .label {
    @apply text-[.8125rem] leading-[1rem] tracking-[0.0125rem] text-battleship md:text-[.75rem] md:leading-[.875rem] xl:text-[.8125rem] xl:leading-[1rem];
  }
}

